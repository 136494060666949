import {
  ActionButton,
  Dropdown,
  IconButton,
  IDropdown,
  IDropdownOption,
  IStackProps,
  ITextField,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  Toggle,
} from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Webcam from "react-webcam";
import "./WebcamCapture.css";
import { v4 as uuid } from "uuid";
import { AutocompleteSearchBox, RenderIf } from "../libs";
import { stackStyles, stackTokens } from "../common/styles/StackStyles";

interface IVideoConstraints {
  width: number;
  height: number;
  facingMode: string | { exact: "environment" };
}

const default_videoConstraints: IVideoConstraints = {
  width: 220,
  height: 200,
  facingMode: { exact: "environment" },
};

interface ISubmitStatus {
  success: boolean;
  message: string;
  details?: ISuccessResponse;
}

interface ISuccessResponse {
  issueUuid: string;
}

interface IWebcamCaptureParams {
  zone: string | null;
}

interface IIssueType {
  id: number,
  name: string
}

interface IAssetCategory {
  id: number;
  name: string;
  code: string;
  issueTypes: IIssueType[] | null;
  subCategories: IAssetCategory[] | null;
}

interface IZone {
  id: number;
  name: string;
  assetCategories: IAssetCategory[] | null;
}

const WebcamCapture = (params: IWebcamCaptureParams) => {
  const { register, handleSubmit } = useForm();
  const webcamRef = React.useRef<any>(null);
  const [image, setImage] = useState("");
  const [status, setStatus] = useState<ISubmitStatus>();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [showCamera, setShowCamera] = useState(false);
  const [zone, setZone] = useState<IZone>();

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }
  }, [webcamRef]);

  const setCameraFacingModeClicked = () => {
    setCameraFacingMode({
      width: default_videoConstraints.width,
      height: default_videoConstraints.height,
      facingMode:
        cameraFacingMode.facingMode === "user"
          ? { exact: "environment" }
          : "user",
    });
  };

  const [state, setState] = useState("");
  const [anonymous, setAnonymous] = useState(true);

  const [zoneAssetCat, setZoneAssetCat] = useState<IAssetCategory>();
  const [zoneAssetSubCat, setZoneAssetSubCat] = useState<IAssetCategory>();
  const [zoneAssetSubCat2, setZoneAssetSubCat2] = useState<IAssetCategory>();

  const [issueTypeId, setIssueTypeId] = useState<number | undefined>(undefined);

  const nameInput = useRef(null);
  const mobileInput = useRef(null);
  const emailInput = useRef(null);
  const issueTypeInput = useRef(null);

  interface IAsset {
    code?: string;
    note?: string;
    uuid?: string;
    zoneId: number;
    supportedIssues?: IIssueType[];
  }
  const [asset, setAsset] = useState<IAsset>({ zoneId: 0 });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assetIsSearched, setAssetIsSearched] = useState(false);
  const [flattenAssetCats, setFlattenAssetCats] = useState(new Map<string, IAssetCategory>());

  const [suggestions, setSuggestions] = useState<string[]>();
  const flattenCats = (cats: IAssetCategory[] | null, map: Map<string, IAssetCategory>, path: string) => {
    if (!cats) {
      return;
    }
    for (const cat of cats) {
      map.set(cat.name, cat);
      flattenCats(cat.subCategories, map, path);
    };
  }

  const emulationMode = searchParams.get("emulationMode");
  useEffect(() => {
    if (params.zone) {
      console.log("Loading zone details");
      setState("Loading zone details");
      fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/zones?id=${params.zone}&code=${process.env.REACT_APP_BACKEND_API_KEY}`
      )
        .then(
          (response) => response.json(),
          (err) => {
            throw err;
          }
        )
        .then((data: IZone) => {
          console.log(data);
          setZone(data);
          const catsMap = new Map<string, IAssetCategory>();
          flattenCats(data.assetCategories, catsMap, '/');
          const nodesMap = new Map<string, IAssetCategory>();
          catsMap.forEach((v, k) => {
            if (v.subCategories?.length === 0) {
              nodesMap.set(k, v);
            }
          })
          setFlattenAssetCats(nodesMap);
          setState("");
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Couldn't get the item details. Error: ${error.message}`);
        });
    } else {
      const code = searchParams.get("code");
      const filter = id ? `id=${id}` : `uuid=${code}`;
      setState("Loading asset details");
      fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/assets?${filter}&code=${process.env.REACT_APP_BACKEND_API_KEY}`
      )
        .then(
          (response) => response.json(),
          (err) => {
            throw err;
          }
        )
        .then((data) => {
          setAsset({ ...data.asset });
          setState("");
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Couldn't get the item details. Error: ${error.message}`);
        });
    }
  }, [id, searchParams, params.zone]);

  const zoneId = params.zone ? Number(params.zone) : asset.zoneId;
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    setIsSubmitting(true);
    data = {
      ...data,
      uuid: uuid(),
      zoneId: zoneId,
      zoneAssetCat: zoneAssetSubCat2 ? Number(zoneAssetSubCat2.id) :
        zoneAssetSubCat ? Number(zoneAssetSubCat.id) :
          zoneAssetCat ? Number(zoneAssetCat.id) : undefined,
      image: image,
      isAnonymous: anonymous,
      name: (nameInput?.current as unknown as ITextField).value,
      mobile: (mobileInput?.current as unknown as ITextField).value,
      email: (emailInput?.current as unknown as ITextField).value,
      issueTypeId: params.zone ? issueTypeId :
        (asset.supportedIssues && asset.supportedIssues.length > 0) ?
          Number((issueTypeInput?.current as unknown as IDropdown).selectedOptions[0].key) : undefined
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      // "https://webhook.site/d7c2651d-be58-4d8e-a112-453f39ea9855",
      `${process.env.REACT_APP_BACKEND_API_URL}/v1/issues?${asset.uuid ? `uuid=${asset.uuid}` : `zone=${zoneId}`}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
      requestOptions
    )
      .then((response: any) => {
        if (!response.ok) {
          throw response.statusText;
        }
        return response.json();
      })
      .then(
        (data: ISuccessResponse) => {
          setStatus({
            success: true,
            message: `Issue created successfully: ${data.issueUuid}`,
            details: data,
          });
          if (!emulationMode) {
            navigate(`/success?issue=${data.issueUuid}&${params.zone ? `zone=${zoneId}` : `asset=${asset.code}`}`);
          }
        },
        (reason) => {
          setStatus({
            success: false,
            message: `Issue submission failed: ${reason}`,
          });
        }
      ).finally(() => {
        setIsSubmitting(false);
      });
  };

  const [cameraFacingMode, setCameraFacingMode] = useState<IVideoConstraints>(
    default_videoConstraints
  );

  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    // styles: { root: { width: 300 } },
  };

  function _onCameraToggleChange(
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) {
    setShowCamera(!!checked);
  }

  return (
    <Stack horizontal horizontalAlign="center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack tokens={stackTokens} styles={stackStyles}>
          <RenderIf condition={!emulationMode}>
            <img src={process.env.REACT_APP_LOGO_URL} alt="Logo" className="App-logo" />
            <Text variant="large">Thanks for reporting the issue. </Text>
          </RenderIf>
          <RenderIf condition={!!state}>
            <Text variant="smallPlus">{state}</Text>
          </RenderIf>
          <RenderIf condition={!!params.zone}>
            <Stack horizontal tokens={stackTokens}>
              <Label>Zone: </Label>
              <TextField
                type="text"
                placeholder={zone?.name}
                disabled
              />
            </Stack>
            <Text>Please type the Asset name</Text>
            <AutocompleteSearchBox
              suggestions={suggestions}
              onClear={() => setAssetIsSearched(false)}
              onChange={(_, newText) => {
                if (!newText || newText.trim() === "") {
                  setSuggestions(undefined);
                } else {
                  const cats = Array.from(flattenAssetCats.keys());
                  setSuggestions(
                    cats.filter((cat) =>
                      cat.toLowerCase().includes(newText.toLowerCase())
                    ));
                }
              }}
              onSuggestionClicked={(newValue) => {
                if (typeof newValue !== 'string') {
                  return;
                }
                const cat = flattenAssetCats.get(newValue)
                if (cat) {
                  setZoneAssetSubCat2(cat);
                  setAssetIsSearched(true);
                }
                else {
                  setAssetIsSearched(false);
                }
              }} />
            {!assetIsSearched && <>
              <Text>Please select the type of Asset</Text>
              <Dropdown options={zone?.assetCategories?.map((aCat: any) => ({
                key: aCat.id,
                text: aCat.name,
              })) ?? []}
                onChange={(e, option?: IDropdownOption) => {
                  const catId = Number(option?.key ?? '');
                  const cat = zone?.assetCategories?.find(aCat => aCat.id === catId);
                  setZoneAssetCat(cat);
                  setZoneAssetSubCat(undefined);
                  setIssueTypeId(undefined);
                }}></Dropdown>
              {zoneAssetCat?.subCategories && zoneAssetCat?.subCategories.length > 0 ?
                <Dropdown
                  options={zoneAssetCat?.subCategories?.map((aCat: any) => ({
                    key: aCat.id,
                    text: aCat.name,
                  })) ?? []}
                  onChange={(e, option?: IDropdownOption) => {
                    const subCatId = Number(option?.key ?? '');
                    const subCat = zoneAssetCat?.subCategories?.find(aCat => aCat.id === subCatId);
                    setZoneAssetSubCat(subCat);
                    setIssueTypeId(undefined);
                  }} /> :
                <></>}
              {zoneAssetSubCat?.subCategories && zoneAssetSubCat?.subCategories.length > 0 ?
                <Dropdown
                  options={zoneAssetSubCat?.subCategories?.map((aCat: any) => ({
                    key: aCat.id,
                    text: aCat.name,
                  })) ?? []}
                  onChange={(e, option?: IDropdownOption) => {
                    const subCatId = Number(option?.key ?? '');
                    const subCat = zoneAssetSubCat?.subCategories?.find(aCat => aCat.id === subCatId);
                    setZoneAssetSubCat2(subCat);
                    setIssueTypeId(undefined);
                  }} /> :
                <></>}
            </>}
            {zoneAssetSubCat?.issueTypes && zoneAssetSubCat?.issueTypes.length > 0 ?
              <>
                <Text>Please select the type of issue</Text>
                <Dropdown
                  options={zoneAssetSubCat?.issueTypes?.map((issueType: any) => ({
                    key: issueType.id,
                    text: issueType.name
                  })) ?? []}
                  onChange={(e, option?: IDropdownOption) => {
                    setIssueTypeId(Number(option?.key ?? ''));
                  }}
                />
              </> : <></>
            }
            {zoneAssetSubCat2?.issueTypes && zoneAssetSubCat2?.issueTypes.length > 0 ?
              <>
                <Text>Please select the type of issue</Text>
                <Dropdown
                  options={zoneAssetSubCat2?.issueTypes?.map((issueType: any) => ({
                    key: issueType.id,
                    text: issueType.name
                  })) ?? []}
                  onChange={(e, option?: IDropdownOption) => {
                    setIssueTypeId(Number(option?.key ?? ''));
                  }}
                />
              </> : <></>
            }
          </RenderIf>
          <RenderIf condition={!params.zone}>
            <Text>You are reporting issue about following item:</Text>
            <TextField
              label="Item"
              type="text"
              placeholder={asset.note}
              disabled
            />
            <TextField
              label="Code"
              type="text"
              placeholder={asset.code}
              disabled
            />
          </RenderIf>
          <Stack {...columnProps} horizontalAlign="center">
            <Toggle
              label="Attach a picture?"
              inlineLabel
              onChange={_onCameraToggleChange}
            />
            <RenderIf condition={!!showCamera}>
              <div>
                <RenderIf condition={image !== ""}>

                  <Stack horizontalAlign="center">
                    <img src={image} alt="" />
                    <Stack horizontal>
                      <ActionButton
                        iconProps={{ iconName: "Undo" }}
                        onClick={() => {
                          setImage("");
                        }}
                      >
                        Retake
                      </ActionButton>
                    </Stack>
                  </Stack>
                </RenderIf>
                <RenderIf condition={image === ""}>
                  <div className="CaptureButtonContainer">
                    <Stack horizontalAlign="center">
                      <Webcam
                        audio={false}
                        height={200}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={220}
                        videoConstraints={cameraFacingMode}
                      />
                      <IconButton
                        iconProps={{ iconName: "Camera" }}
                        className="CaptureButtonStyle"
                        onClick={capture}
                      />
                      <Stack horizontal>
                        <ActionButton
                          iconProps={{ iconName: "RepeatAll" }}
                          onClick={setCameraFacingModeClicked}
                        >
                          Reverse camera
                        </ActionButton>
                      </Stack>
                    </Stack>
                  </div>
                </RenderIf>
              </div>
            </RenderIf>
          </Stack>
          <Stack>
            {asset.supportedIssues && asset.supportedIssues.length > 0 ?
              <Dropdown options={asset.supportedIssues.map(si => ({
                key: si.id,
                text: si.name
              }))}
                componentRef={issueTypeInput}
              ></Dropdown> : <></>}
            <TextField
              label="Describe the issue"
              type="text"
              multiline
              rows={3}
              {...register("comment")}
            />
            <Toggle
              label="Anonymous"
              defaultChecked={anonymous}
              inlineLabel
              onChange={(_, checked) => setAnonymous(!!checked)}
            />
            <TextField
              label="Please enter your name"
              type="text"
              componentRef={nameInput}
              disabled={anonymous}
            // {...register("name", { required: false })}
            />
            <TextField
              label="Your mobile"
              type="text"
              required={!anonymous}
              componentRef={mobileInput}
              disabled={anonymous}
            // {...register("mobile", { required: false })}
            />
            <TextField
              label="Your email"
              type="text"
              required={!anonymous}
              componentRef={emailInput}
              disabled={anonymous}
            // {...register("email", { required: false })}
            />
            <PrimaryButton type="submit" disabled={isSubmitting}>Submit</PrimaryButton>
            <RenderIf condition={status !== undefined}>
              <MessageBar
                messageBarType={
                  status?.success
                    ? MessageBarType.success
                    : MessageBarType.error
                }
              >
                {status?.message}, Issue:{" "}
                {status?.details?.issueUuid?.substring(0, 8)}
              </MessageBar>
            </RenderIf>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default WebcamCapture;
