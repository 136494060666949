import { useParams, useSearchParams } from "react-router-dom";
import ManualIdEntry from './ManualIdEntry';
import WebcamCapture from './WebcamCapture'

const Home = () => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const code: string = searchParams.get("code") || '';
  const zone: string | null = searchParams.get("zone");

  return (
    (!id && !code && !zone) ? (
      <ManualIdEntry />
    ) : (
      <WebcamCapture zone={zone}/>
    )
  )
}

export default Home;